/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { mainTheme } from '../../Theme';
import { Form, Field, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
// import { ActionTypeSelector } from '../selectors/ActionType';
// import { OutcomeSelector } from '../selectors/OutcomeSelector';
import { client } from '../..';
import { showNotification } from '../../App';
import {
    FollowUpEnquiryMutation,
    EnquiryDetailsQueryParams,
    retrieveEnquiryDetailsData,
    EnquiryDefaultConfig,
    retrieveEnquiryDefaultSettings,
    EnquiryDefaultSettings
} from '../EnquirySummaryRepository';
import moment from 'moment';
import { RvLoader } from '../../components/Loader';
import { EnquiryDetail, EnquiryData } from '../models/Enquiry';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { addBusinessDays } from '../drawers/EnquiryFormDrawer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // width: '100%',
            // show autocomplete when overlapping the dialog container
            // '& .MuiDialog-container > div': {
            //     overflowY: 'visible'
            // },
            // '& .MuiDialogContent-root': {
            //     overflowY: 'inherit'
            // }
        },
        textField: {
            width: '100%',
            flex: 1,
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
            '& .MuiTypography-root': {
                display: 'flex',
                margin: '0 -10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .col': {
                    padding: '0 10px',
                    fontSize: '12px',
                    color: '#000',
                }
            }
        },
        hideDate: {
            display: 'none'
        },
        startTimeWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
        },
        formControlLabel: {
            marginLeft: 0,
            marginRight: 0,
        },
        textFieldWrapper: {
            flex: 1,
        },
        staffWrapper: {
            fontSize: 16,
            color: mainTheme.TemplateColor.Primary,
        },
        divider: {
            width: '100%',
        },
        selector: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end'
        }
    })
);

export interface FollowUp {
    guid?: string;
    followUpAction: string | null;
    followUpActionSystemName: string | null;
    followUpSystemAction: string | null;
    followUpDate: Date | null;
    followUpNotes: string;
    hasFollowUpNotesMentions: boolean;
    outcome: string | null;
    outcomeDate: Date | null;
    outcomeNotes: string | null;
    reason: string | null;
    reasonGuidID: string | null;
    isFormChanged: boolean;
    staff: IAutoCompleteItem | null;
    startTime: Date | null;
    endTime: Date | null;
    isAllDay: boolean;
    officeLocation: IAutoCompleteItem | null;
    feeApplicable: number | null;
    isSendConfirmation: boolean;
    meetingNotes: string | null;

    emailContent: string | null;
    emailSubject: string | null;
    fromEmailAddress: string | null;
    toEmailAddress: string | null;
    ccEmailAddress: string | null;

    emailTemplate: IAutoCompleteItem | null;

    followUpDueReminder: IAutoCompleteItem | null;

    updateFollowUpDueForInternalNote: boolean;
}

interface MessageAssigneeDialogProps {
    guid?: string;
    onDialogClose?: () => void;
}

interface MessageAssigneeDialogState {
    isFormChanged: boolean;
    showDiscard: boolean;
    isSendEmailDialogOpen: boolean;
    enquiryDefaultConfig?: EnquiryDefaultConfig;
}

// tslint:disable-next-line: no-anyW
export const MessageAssigneeDialog: React.FunctionComponent<MessageAssigneeDialogProps> = props => {
    const classes = useStyles();
    // tslint:disable-next-line: no-any
    // const { isFollowUpOpen, onFollowUpClose }: any = useContext(EnquirySummaryContext);
    const [isLoading, setIsLoading] = useState(false);

    const [enquiryDetails, setEnquiryDetails] = useState<EnquiryDetail>({
        guid: props.guid,
        createdDate: moment().toDate(),
        createdByName: '',
        lastUpdated: moment().toDate(),
        isClosed: false,
        isContactCreated: false,
        isMatterCreated: false,
        firstName: '',
        lastName: '',
        email: '',
        gender: 'Unknown',
        initialEnquiryDate: moment().toDate(),
        phone: '',
        mobile: '',
        sourceGuidID: null,
        source: '',
        isSourceReferrer: false,
        officeGuidID: null,
        office: '',
        sourceNotes: '',

        organisation: '',
        positionAtOrganisation: '',

        areaOfLaw: '',
        practiceAreaGuidID: null,
        natureOfEnquiry: '',

        internalNotes: null,
        files: [],

        assignedTo: null,
        assignedToName: null, // Display Name
        assignedToEmailAddress: null,
        assignedBy: null,
        assignedByName: null, // Display Name
        assignedByEmailAddress: null,
        dateAssigned: moment().toDate(),
        followUpDueDate: null,
        assignmentReminderOffset: null,

        followUpActionGuidID: null,
        followUpAction: null,
        followUpActionSystemName: null,
        followUpSystemAction: null,
        followUpDate: null,
        outcomeGuidID: null,
        outcome: null,
        outcomeDate: null,
        outcomeNotes: '',
        reasonGuidID: null,
        reason: null,
        followUpNotes: null,
        isKeyOpportunity: false,
        referrerGuid: null,
        referrerName: null,
        nameGuid: null,
        nameTitle: null,
        organisationGuid: null,

        estimatedFees: null,
        isEstimatedFeesIncludesTax: false,
        estimatedDisbursements: null,
        isEstimatedDisbursementsIncludesTax: false,
        enquiryQuality: null,
        likelihoodToProceed: null,
        clientFirstName: null,
        clientLastName: null,
        dOB: null,
        isMarketingConsent: false,
        isTermsAndConditionsApproved: false,
        isPrivacyStatementProvided: false,
        isConflictCheckPerformed: false,
        isNoConflict: false,
        isIdentityCheckPerformed: false,
        addressName: null,
        floor: null,
        number: null,
        street: null,
        clientAddressStreet2: null,
        suburb: null,
        state: null,
        postcode: null,
        country: null,
        pOBox: null,
        postalSuburb: null,
        postalState: null,
        postalPostCode: null,
        postalCountry: null,

        referredToFirmGuid: null,
        referredToFirmName: '',
        notProceedingOutcomeNotes: null,

        // hasFollowUpNotesMentions: false
        clientRelationshipToEnquirer: null,

        matterId: null,
        matterGuid: null,
        matterTitle: null,
        matterFileNumber: null,

        assignedToIsStaff: false,
        assignedToStaffGuid: null,
        assignedToStaffName: null,

        otherPartyFirstName: null,
        otherPartyLastName: null,
        otherPartyNotes: null,
        otherPartyEmail: null,
        otherPartyPhone: null,
        otherPartyMobile: null,
        otherPartyOrganisation: null,

        enquiryNotes: null,

        pOBoxType: null,

        integrationContactId: null,
        integrationClientId: null,
        integrationOtherPartyId: null,
        integrationMatterId: null,
        integrationMatterDocumentId: null,
        otherPartyDOB: null,
        otherPartyAddressName: null,
        otherPartyFloor: null,
        otherPartyNumber: null,
        otherPartyStreet: null,
        otherPartyStreet2: null,
        otherPartySuburb: null,
        otherPartyState: null,
        otherPartyPostcode: null,
        otherPartyCountry: null,

        otherPartyPOBoxType: null,
        otherPartyPOBox: null,
        otherPartyPostalSuburb: null,
        otherPartyPostalState: null,
        otherPartyPostalPostCode: null,
        otherPartyPostalCountry: null,
        otherPartyNameGuid: null,
        otherPartyOrganisationGuid: null,
        isRiskAssessed: null,
        riskLevel: null,
        riskLevelGuid: null,
        riskNotes: null,

        secondClientFirstName:                    null,
        secondClientLastName:                     null,
        secondClientRelationshipToEnquirer:       null,
        secondClientGender:                       null,
        secondClientSuburb:                       null,
        secondClientState:                        null,
        secondClientPostCode:                     null,
        secondClientIsMarketingConsent:           false,
        secondClientIsTermsAndConditionsApproved: false,
        secondClientIsPrivacyStatementProvided:   false,
        secondClientIsConflictCheckPerformed:     false,
        secondClientIsIdentityCheckPerformed:     false,
        secondClientIsNoConflict:                 false,
        secondClientCountry:                       null,
        secondClientAddressName:                   null,
        secondClientFloor:                         null,
        secondClientNumber:                        null,
        secondClientStreet1:                       null,
        secondClientStreet2:                       null,
        secondClientDOB:                           null,
        secondClientPOBoxType:                     null,
        secondClientPOBox:                         null,
        secondClientPostalSuburb:                  null,
        secondClientPostalState:                   null,
        secondClientPostalPostCode:                null,
        secondClientPostalCountry:                 null,
        secondClientGuid: null,

        responseText: null,

        eventAndCampaignGuid: null,
        eventAndCampaignName: null,
        eventAndCampaignNotes: null,
        eventAndCampaignNotesGuid: null,

        isNameFromDataSource: null,
        isOrganisationFromDataSource: null,
        isOtherSideFromDataSource: null,
        isSecondClientFromDataSource: null,

        clientTitle: null,
        otherPartyTitle: null,
        secondClientTitle: null,
        title: null,
        clientEmail: null,
        clientGuid: null,
        clientMobile: null,
        clientPhone: null,
        secondClientEmail: null,
        secondClientMobile: null,
        secondClientPhone: null,

        customFields: []
    });

    const [state, setState] = useState<MessageAssigneeDialogState>({
        showDiscard: false,
        isFormChanged: false,
        isSendEmailDialogOpen: false
    });

    useEffect(() => {
        // - Ran only once
        // If we recieve a guid, then fetch EnquiryDetails
        if (props.guid) {
            setIsLoading(true);
            fetchEnquiryDefaultSettings();
            fetchEnquiryDetails(props.guid);
        }
        // tslint:disable-next-line: align
    }, [props.guid]);

    const fetchEnquiryDefaultSettings = () => {
        retrieveEnquiryDefaultSettings(
            false,
            // tslint:disable-next-line: no-any
            (data: any) => onDataEnquiryDefaultSettingsRetrieved(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            });
    };

    const onDataEnquiryDefaultSettingsRetrieved = (data: EnquiryDefaultSettings) => {
        if (data && data.settings && data.settings.systems && data.settings.systems.enquiryDefaults
            && data.settings.systems.enquiryDefaults.config) {
            setState((prevState) => {
                return {
                    ...prevState,
                    enquiryDefaultConfig: data.settings.systems.enquiryDefaults.config
                };
            });
        }
    };

    const displayLoader = () => {
        if (isLoading) {
            return <RvLoader />;
        } else {
            return null;
        }
    };

    const fetchEnquiryDetails = (guid: string) => {
        var enquiryQueryParams: EnquiryDetailsQueryParams = {
            guid: guid,
        };

        retrieveEnquiryDetailsData(
            enquiryQueryParams,
            true,
            (data) => onDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const onDataRetrieved = (data: EnquiryData) => {
        setIsLoading(false);
        setEnquiryDetails(data.enquiry.detail);
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any
    ) => {
        return (
            <div>
                <Button
                    type="submit"
                    color="primary"
                    disabled={submitting || pristine}
                >
                    Send
                </Button>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </div>
        );
    };

    const onSubmit = (values: FollowUp) => {
        saveFollowUp(values);
    };

    const saveFollowUp = (values: FollowUp) => {
        // Save the File
        client.mutate({
            mutation: FollowUpEnquiryMutation,
            variables: {
                input: getFollowUpEnquiryInput(values)
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any; }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        onFollowUpClosed();
                    } else {
                        showNotification('Failed to Save Follow up', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => { showNotification('Failed to Save Follow up', reason, 'error'); });
    };

    const getFollowUpEnquiryInput = (values: FollowUp) => {
        return {
            enquiryGuid: props.guid,
            followUpAction: 'Internal Note',
            followUpActionSystemName: 'InternalNote',
            followUpDate: moment(values.followUpDate).toDate(),
            outcome: 'More Follow Up',
            outcomeDate: moment(values.outcomeDate).toDate(),
            outcomeNotes: '',
            reason: null,
            reasonGuid: null,
            followUpNotes: values.followUpNotes,
            reminderOffset: null,
            updateFollowUpDueForInternalNote: false, // Internal notes to be sent to the assigned person. So don't update the follow up due date
            messageAssignee: true
        };
    };

    const getOutcomeDateDefaultValue = () => {
        // check outcome date (FollowUpDue)
        let outcomeDate: Date | null = null;

        if (state.enquiryDefaultConfig) {

            let hours = 0;
            let minutes = 0;
            let validTimeOfDay = false;

            if (state.enquiryDefaultConfig.timeOfDay && state.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
                var timeOfDaySplit = state.enquiryDefaultConfig.timeOfDay ? state.enquiryDefaultConfig.timeOfDay.split(':') : null;

                if (timeOfDaySplit) {
                    validTimeOfDay = true;
                    hours = Number(timeOfDaySplit[0]);
                    minutes = Number(timeOfDaySplit[1]);
                }
            }

            if (state.enquiryDefaultConfig.isCalculatedDays) {
                let date = addBusinessDays(moment().toDate(), state.enquiryDefaultConfig.addDays ? state.enquiryDefaultConfig.addDays : 0);

                if (validTimeOfDay) {
                    outcomeDate = date
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                } else {
                    outcomeDate = date.toDate();
                }
            } else if (state.enquiryDefaultConfig.isCalculatedHours) {
                let addhours = state.enquiryDefaultConfig.addHours ? state.enquiryDefaultConfig.addHours : 0;

                outcomeDate = moment()
                    .add(addhours, 'hour')
                    .toDate();
            }

            // Manual default time is set through getOutcomeDefaultTime()
        }

        return outcomeDate;
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<any>, changeProps: any) => {
        if (!changeProps.pristine) {
            form.change('isFormChanged', true);
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            onFollowUpClosed();
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            onFollowUpClosed();
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });
        }
    };

    const onFollowUpClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    return (
        <>
            <DialogBox
                title="Message Assignee"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard ? state.showDiscard : false}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                className={classes.root}
                fullWidth={true}
                maxWidth="sm"
                scroll="paper"
            >
                {isLoading
                    ? displayLoader()
                    : (
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                followUpAction: 'Internal Note',
                                followUpDate: new Date(),
                                followUpNotes: '',
                                hasFollowUpNotesMentions: false,
                                outcome: 'More Follow Up',
                                outcomeDate: getOutcomeDateDefaultValue(),
                                outcomeNotes: '',
                                reason: '',
                                reasonGuid: null,
                                isFormChanged: false,
                                staff: null,
                                startTime: null,
                                endTime: null,
                                isAllDay: false,
                                officeLocation: null,
                                feeApplicable: null,
                                isSendConfirmation: true,
                                meetingNotes: null,
                                emailTemplate: null,
                                followUpDueReminder: 'None',
                                updateFollowUpDueForInternalNote: false,
                            }}
                            initialValuesEqual={() => true}
                            // keepDirtyOnReinitialize={true}
                            subscription={{ submitting: true, pristine: true, values: true }}
                            render={({ handleSubmit, form, submitting, pristine, values
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    {displayLoader()}
                                    <FormSpy
                                        subscription={{ pristine: true }}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={props => {
                                            onFormValueChanged(form, props);
                                        }}
                                    />
                                    <DialogTitle id="form-dialog-title" className={classes.dialogTitle} >
                                        <div>Message Assignee</div>
                                        <div className={'col'}>
                                            {enquiryDetails.assignedTo
                                                ? (
                                                    'Assigned to ' + enquiryDetails.assignedToName +
                                                    (
                                                        enquiryDetails.followUpDueDate && enquiryDetails.followUpDueDate !== null ? (
                                                            ', due by ' + (enquiryDetails.followUpDueDate
                                                                ? moment(enquiryDetails.followUpDueDate).format('DD MMM YYYY')
                                                                : 'None')
                                                        ) : ''
                                                    )
                                                ) : ''
                                            }
                                        </div>
                                    </DialogTitle>
                                    <DialogContent dividers={true}>
                                        <Grid container={true} spacing={3}>
                                            <Field
                                                name="followUpNotes"
                                                validate={required}
                                                subscription={{ touched: true, error: true, value: true }}
                                            >
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        label="Message"
                                                        type="text"
                                                        required={true}
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        autoComplete="abcd"
                                                        multiline={true}
                                                        rows={2}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        {/* {printJson(values)} */}
                                    </DialogContent>
                                    <DialogActions>
                                        {actionButton(form, submitting, pristine, values)}
                                    </DialogActions>
                                </form>
                            )}
                        />
                    )
                }
            </Dialog>
        </>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: FollowUp) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }