/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserSelector from '../../components/UserSelector';
import { IDownShiftItem } from 'mui-downshift';
import { mainTheme } from '../../Theme';
import { Form, Field, FormSpy, FieldInputProps, AnyObject } from 'react-final-form';
import { FormApi } from 'final-form';
import {
    AssignStaffToEnquiryMutation,
    EnquiryDefaultConfig,
    EnquiryDefaultSettings,
    EnquiryDetailsQueryParams,
    retrieveEnquiryDefaultSettings,
    retrieveEnquiryDetailsData,
} from '../EnquirySummaryRepository';
import { client } from '../..';
import { showNotification } from '../../App';
import moment from 'moment';
import { RvLoader } from '../../components/Loader';
import { EnquiryDetail, EnquiryData } from '../models/Enquiry';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { addBusinessDays } from '../drawers/EnquiryFormDrawer';
import { ReminderSelector } from '../../components/ReminderSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // show autocomplete when overlapping the dialog container
            '& .MuiDialog-container > div': {
                overflowY: 'visible',
            },
        },
        textField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
            '& .MuiTypography-root': {
                display: 'flex',
                margin: '0 -10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .col': {
                    padding: '0 10px',
                    fontSize: '12px',
                    color: '#000',
                },
            },
        },
        selector: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
        },
    })
);

export interface AssignLawyer {
    guid?: string;

    dateAssigned: Date | null;
    assignedTo: string | null;
    assignedToName: string | null;
    followUpDueDate: Date | null;
    assignedBy: string | null;
    assignedByName: string | null;
    assignmentFollowUpDueReminder: IAutoCompleteItem | null;
}

interface AssignDialogProps {
    guid?: string;
    assignedBy: string;
    assignedByName: string;
    onDialogClose?: () => void;
}

interface AssignDialogState {
    isFormChanged: boolean;
    showDiscard: boolean;
    enquiryDefaultConfig?: EnquiryDefaultConfig;
}

// tslint:disable-next-line: no-anyW
export const AssignDialog: React.FC<AssignDialogProps> = (props) => {
    const classes = useStyles();
    // tslint:disable-next-line: no-any
    // const { isAssignOpen, onAssignClose }: any = useContext(EnquirySummaryContext);

    const [isLoading, setIsLoading] = useState(true);
    const [enquiryDetail, setEnquiryDetails] = useState<EnquiryDetail>({
        guid: props.guid,
        createdDate: moment().toDate(),
        createdByName: '',
        lastUpdated: moment().toDate(),
        isClosed: false,
        isContactCreated: false,
        isMatterCreated: false,
        firstName: '',
        lastName: '',
        email: '',
        gender: 'Unknown',
        initialEnquiryDate: moment().toDate(),
        phone: '',
        mobile: '',
        sourceGuidID: null,
        source: '',
        isSourceReferrer: false,
        officeGuidID: null,
        office: '',
        sourceNotes: '',

        organisation: '',
        positionAtOrganisation: '',

        areaOfLaw: '',
        practiceAreaGuidID: null,
        natureOfEnquiry: '',

        internalNotes: null,
        files: [],

        assignedTo: null,
        assignedToName: null, // Display Name
        assignedToEmailAddress: null,
        assignedBy: null,
        assignedByName: null, // Display Name
        assignedByEmailAddress: null,
        dateAssigned: moment().toDate(),
        followUpDueDate: null,
        assignmentReminderOffset: null,

        followUpActionGuidID: null,
        followUpAction: null,
        followUpActionSystemName: null,
        followUpSystemAction: null,
        followUpDate: null,
        outcomeGuidID: null,
        outcome: null,
        outcomeDate: null,
        outcomeNotes: null,
        reasonGuidID: null,
        reason: null,
        followUpNotes: null,
        isKeyOpportunity: false,
        referrerGuid: null,
        referrerName: null,
        nameGuid: null,
        nameTitle: null,
        organisationGuid: null,

        estimatedFees: null,
        isEstimatedFeesIncludesTax: false,
        estimatedDisbursements: null,
        isEstimatedDisbursementsIncludesTax: false,
        enquiryQuality: null,
        likelihoodToProceed: null,
        clientFirstName: null,
        clientLastName: null,
        dOB: null,
        isMarketingConsent: false,
        isTermsAndConditionsApproved: false,
        isPrivacyStatementProvided: false,
        isConflictCheckPerformed: false,
        isNoConflict: false,
        isIdentityCheckPerformed: false,
        addressName: null,
        floor: null,
        number: null,
        street: null,
        clientAddressStreet2: null,
        suburb: null,
        state: null,
        postcode: null,
        country: null,
        pOBox: null,
        postalSuburb: null,
        postalState: null,
        postalPostCode: null,
        postalCountry: null,

        referredToFirmGuid: null,
        referredToFirmName: '',
        notProceedingOutcomeNotes: null,

        clientRelationshipToEnquirer: null,

        matterId: null,
        matterGuid: null,
        matterTitle: null,
        matterFileNumber: null,

        assignedToIsStaff: false,
        assignedToStaffGuid: null,
        assignedToStaffName: null,

        otherPartyFirstName: null,
        otherPartyLastName: null,
        otherPartyNotes: null,
        otherPartyEmail: null,
        otherPartyPhone: null,
        otherPartyMobile: null,
        otherPartyOrganisation: null,

        enquiryNotes: null,

        pOBoxType: null,

        integrationContactId: null,
        integrationClientId: null,
        integrationOtherPartyId: null,
        integrationMatterId: null,
        integrationMatterDocumentId: null,
        otherPartyDOB: null,
        otherPartyAddressName: null,
        otherPartyFloor: null,
        otherPartyNumber: null,
        otherPartyStreet: null,
        otherPartyStreet2: null,
        otherPartySuburb: null,
        otherPartyState: null,
        otherPartyPostcode: null,
        otherPartyCountry: null,

        otherPartyPOBoxType: null,
        otherPartyPOBox: null,
        otherPartyPostalSuburb: null,
        otherPartyPostalState: null,
        otherPartyPostalPostCode: null,
        otherPartyPostalCountry: null, 
        otherPartyNameGuid: null,
        otherPartyOrganisationGuid: null,

        isRiskAssessed: null,
        riskLevel: null,
        riskLevelGuid: null,
        riskNotes: null,

        secondClientFirstName:                    null,
        secondClientLastName:                     null,
        secondClientRelationshipToEnquirer:       null,
        secondClientGender:                       null,
        secondClientSuburb:                       null,
        secondClientState:                        null,
        secondClientPostCode:                     null,
        secondClientIsMarketingConsent:           false,
        secondClientIsTermsAndConditionsApproved: false,
        secondClientIsPrivacyStatementProvided:   false,
        secondClientIsConflictCheckPerformed:     false,
        secondClientIsIdentityCheckPerformed:     false,
        secondClientIsNoConflict:                 false,
        secondClientCountry:                       null,
        secondClientAddressName:                   null,
        secondClientFloor:                         null,
        secondClientNumber:                        null,
        secondClientStreet1:                       null,
        secondClientStreet2:                       null,
        secondClientDOB:                           null,
        secondClientPOBoxType:                     null,
        secondClientPOBox:                         null,
        secondClientPostalSuburb:                  null,
        secondClientPostalState:                   null,
        secondClientPostalPostCode:                null,
        secondClientPostalCountry:                 null,
        secondClientGuid: null,

        responseText: null,

        eventAndCampaignGuid: null,
        eventAndCampaignName: null,
        eventAndCampaignNotes: null,
        eventAndCampaignNotesGuid: null,

        isNameFromDataSource: null,
        isOrganisationFromDataSource: null,
        isOtherSideFromDataSource: null,
        isSecondClientFromDataSource: null,

        clientTitle: null,
        otherPartyTitle: null,
        secondClientTitle: null,
        title: null,
        clientEmail: null,
        clientGuid: null,
        clientMobile: null,
        clientPhone: null,
        secondClientEmail: null,
        secondClientMobile: null,
        secondClientPhone: null,

        customFields: [],
    });

    const [state, setState] = useState<AssignDialogState>({
        showDiscard: false,
        isFormChanged: false,
    });

    useEffect(() => {
        // - Ran only once
        // If we recieve a guid, then fetch EnquiryDetails
        if (props.guid) {
            fetchEnquiryDefaultSettings();
            fetchEnquiryDetails(props.guid);
            setIsLoading(true);
        }
        // tslint:disable-next-line: align
    }, [props.guid]);

    const displayLoader = () => {
        if (isLoading) {
            return <RvLoader />;
        } else {
            return null;
        }
    };

    const fetchEnquiryDefaultSettings = () => {
        retrieveEnquiryDefaultSettings(
            true,
            // tslint:disable-next-line: no-any
            (data: any) => onDataEnquiryDefaultSettingsRetrieved(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onDataEnquiryDefaultSettingsRetrieved = (data: EnquiryDefaultSettings) => {
        if (data && data.settings && data.settings.systems && data.settings.systems.enquiryDefaults && data.settings.systems.enquiryDefaults.config) {
            setState((prevState) => {
                return {
                    ...prevState,
                    enquiryDefaultConfig: data.settings.systems.enquiryDefaults.config,
                };
            });
        }
    };

    const fetchEnquiryDetails = (guid: string) => {
        var enquiryQueryParams: EnquiryDetailsQueryParams = {
            guid: guid,
        };

        retrieveEnquiryDetailsData(
            enquiryQueryParams,
            true,
            (data) => onDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const onDataRetrieved = (data: EnquiryData) => {
        setIsLoading(false);
        setEnquiryDetails(data.enquiry.detail);
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    // tslint:disable-next-line: no-any
    const onDropDownSelect = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        values: AnyObject,
        // tslint:disable-next-line: no-any
        input: FieldInputProps<any, HTMLElement>,
        selection: IDownShiftItem,
        name: string
    ) => {
        if (name === 'assignedTo') {
            form.batch(() => {
                form.change('assignedTo', selection.value ? selection.value : null);
                form.change('assignedToName', selection.label ? selection.label : null);

                if (selection.value !== undefined) {
                    // only set assignment's follow up due value when we select assigned to value
                    let followUpDueValue = getFollowUpDueDefaultValue();
                    form.change('followUpDueDate', followUpDueValue);
                }
            });
        } else if (name === 'assignedBy') {
            form.change('assignedBy', selection.value ? selection.value : null);
            form.change('assignedByName', selection.label ? selection.label : null);
        }
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any
    ) => {
        return (
            <div>
                <Button type="submit" color="primary" disabled={submitting || pristine}>
                    Assign
                </Button>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </div>
        );
    };

    const onSubmit = (values: AssignLawyer) => {
        assignLawyer(values);
    };

    const assignLawyer = (values: AssignLawyer) => {
        // Save the File
        client
            .mutate({
                mutation: AssignStaffToEnquiryMutation,
                variables: {
                    input: getAssignStaffToEnquiryInput(values),
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        onAssignClosed();
                    } else {
                        showNotification('Failed to Save Assign Lawyer', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Assign Lawyer', reason, 'error');
            });
    };

    const getAssignStaffToEnquiryInput = (values: AssignLawyer) => {
        return {
            enquiryGuid: props.guid,
            assignedTo: values.assignedTo,
            assignedBy: values.assignedBy,
            dateAssigned: values.dateAssigned === null ? null : moment(values.dateAssigned).toDate(),
            followUpDueDate: values.followUpDueDate === null ? null : moment(values.followUpDueDate).toDate(),
            reminderOffset: values.assignmentFollowUpDueReminder ? values.assignmentFollowUpDueReminder.value : null,
        };
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true,
                };
            });
        }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            onAssignClosed();
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            onAssignClosed();
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });
        }
    };

    const onAssignClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    const getReminderValue = () => {
        if (enquiryDetail && enquiryDetail.assignmentReminderOffset) {
            return { label: enquiryDetail.assignmentReminderOffset, value: enquiryDetail.assignmentReminderOffset };
        } else if (state.enquiryDefaultConfig && state.enquiryDefaultConfig.reminder) {
            return { label: state.enquiryDefaultConfig.reminder, value: state.enquiryDefaultConfig.reminder };
        } else {
            return { label: 'None', value: 'None' };
        }
    };

    const getFollowUpDueDefaultValue = () => {
        // check outcome date (FollowUpDue)
        let outcomeDate: Date | null = null;

        if (state.enquiryDefaultConfig) {
            let hours = 0;
            let minutes = 0;
            let validTimeOfDay = false;

            if (state.enquiryDefaultConfig.timeOfDay && state.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
                var timeOfDaySplit = state.enquiryDefaultConfig.timeOfDay ? state.enquiryDefaultConfig.timeOfDay.split(':') : null;

                if (timeOfDaySplit) {
                    validTimeOfDay = true;
                    hours = Number(timeOfDaySplit[0]);
                    minutes = Number(timeOfDaySplit[1]);
                }
            }

            if (state.enquiryDefaultConfig.isCalculatedDays) {
                let date = addBusinessDays(moment().toDate(), state.enquiryDefaultConfig.addDays ? state.enquiryDefaultConfig.addDays : 0);

                if (validTimeOfDay) {
                    outcomeDate = date.set('hour', hours).set('minute', minutes).toDate();
                } else {
                    outcomeDate = date.toDate();
                }
            } else if (state.enquiryDefaultConfig.isCalculatedHours) {
                let addhours = state.enquiryDefaultConfig.addHours ? state.enquiryDefaultConfig.addHours : 0;

                outcomeDate = moment().add(addhours, 'hour').toDate();
            }

            // Manual default time is set through getOutcomeDefaultTime()
        }

        return outcomeDate;
    };

    const getOutcomeDefaultTime = () => {
        let defaultTime = new Date().setHours(17, 0, 0, 0);

        let hours = 0;
        let minutes = 0;

        if (
            state.enquiryDefaultConfig &&
            state.enquiryDefaultConfig.isManualEntry &&
            state.enquiryDefaultConfig.timeOfDay &&
            state.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date'
        ) {
            var timeOfDaySplit = state.enquiryDefaultConfig.timeOfDay ? state.enquiryDefaultConfig.timeOfDay.split(':') : null;

            if (timeOfDaySplit) {
                hours = Number(timeOfDaySplit[0]);
                minutes = Number(timeOfDaySplit[1]);
            }

            defaultTime = new Date().setHours(hours, minutes, 0, 0);
        }

        return defaultTime;
    };

    return (
        <>
            <DialogBox
                title="Assign"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard ? state.showDiscard : false}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root} fullWidth={true} maxWidth="md">
                {isLoading ? (
                    displayLoader()
                ) : (
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            dateAssigned: new Date(),
                            assignedTo: null,
                            assignedToName: null,
                            assignedBy: props.assignedBy,
                            assignedByName: props.assignedByName,
                            followUpDueDate: null,
                            assignmentFollowUpDueReminder: getReminderValue(),
                        }}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{
                            submitting: true,
                            pristine: true,
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                {displayLoader()}
                                <FormSpy
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={(props) => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                                    <div>Assignment</div>
                                    <div className={'col'}>
                                        {enquiryDetail.assignedTo
                                            ? 'Assigned to ' +
                                              enquiryDetail.assignedToName +
                                              (enquiryDetail.dateAssigned && enquiryDetail.dateAssigned !== null
                                                  ? ' on ' + moment(enquiryDetail.dateAssigned).format('DD MMM YYYY')
                                                  : '')
                                            : ''}
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={6} md={4}>
                                            <FormSpy subscription={{ values: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <div>
                                                        <Field
                                                            name="assignedTo"
                                                            // validate={form.getFieldState('dateAssigned')?.value !== null ? required : undefined}
                                                            // subscription={{touched: true, error: true, value: true}} // cannot subscribe
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    {/* <RenderCount/> */}
                                                                    <UserSelector
                                                                        {...input}
                                                                        label="Assigned To"
                                                                        name="assignedTo"
                                                                        required={true}
                                                                        // tslint:disable-next-line: max-line-length
                                                                        onSelection={(selection: IDownShiftItem, name: string) => {
                                                                            // input.onChange(selection.value);
                                                                            // Another approach.
                                                                            onDropDownSelect(form, values, input, selection, name);
                                                                        }}
                                                                        isGuidAsValue={true}
                                                                        displayLabel={values.assignedToName === null ? undefined : values.assignedToName}
                                                                        isExternal={false}
                                                                        isAuthorized={true}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={6} md={4}>
                                            <FormSpy subscription={{ values: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <div>
                                                        {/* tslint:disable-next-line */}
                                                        {/* {console.log(form.getFieldState('dateAssigned')?.value)} */}

                                                        <Field name="followUpDueDate" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <KeyboardDateTimePicker
                                                                        {...input}
                                                                        className={classes.textField}
                                                                        id="followUpDueDate"
                                                                        name="followUpDueDate"
                                                                        label="Follow Up Due"
                                                                        format="DD/MM/YYYY hh:mm a"
                                                                        placeholder="dd/mm/yyyy hh:mm a"
                                                                        required={true}
                                                                        animateYearScrolling={true}
                                                                        // tslint:disable-next-line: max-line-length
                                                                        autoOk={true}
                                                                        allowKeyboardControl={true}
                                                                        variant={'inline'}
                                                                        error={meta.error && meta.touched}
                                                                        helperText={meta.error && meta.touched ? 'Follow Up Due date is required' : ''}
                                                                        inputProps={{ autocomplete: 'off' }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        // minDate={new Date()}
                                                                        initialFocusedDate={getOutcomeDefaultTime()}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={6} md={4} alignItems="flex-end">
                                            <FormSpy subscription={{ values: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Field name="assignmentFollowUpDueReminder" subscription={{ touched: true, error: true, value: true }}>
                                                        {({ input, meta }) => (
                                                            <div className={classes.selector}>
                                                                <ReminderSelector
                                                                    {...input}
                                                                    label="Reminder"
                                                                    className={classes.textField}
                                                                    onSelection={(selection: IAutoCompleteItem) => {
                                                                        input.onChange(selection);
                                                                    }}
                                                                    error={meta.error && meta.touched}
                                                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                                                    showIcon={true}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={6}>
                                            <FormSpy subscription={{ values: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <div>
                                                        <Field
                                                            name="assignedBy"
                                                            validate={form.getFieldState('assignedTo')?.value !== null ? required : undefined}
                                                            // subscription={{touched: true, error: true, value: true}} // cannot subscribe
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    {/* <RenderCount/> */}
                                                                    <UserSelector
                                                                        {...input}
                                                                        label="Assigned By"
                                                                        name="assignedBy"
                                                                        required={form.getFieldState('assignedTo')?.value !== null ? true : undefined}
                                                                        // tslint:disable-next-line: max-line-length
                                                                        onSelection={(selection: IDownShiftItem, name: string) => {
                                                                            // input.onChange(selection.value);
                                                                            // Another approach.
                                                                            onDropDownSelect(form, values, input, selection, name);
                                                                        }}
                                                                        value={values.assignedBy}
                                                                        displayLabel={values.assignedByName}
                                                                        isGuidAsValue={true}
                                                                        isStaffOnly={true}
                                                                        isAuthorized={true}
                                                                        // displayLabel={values.assignedByName === null
                                                                        //     ? undefined : values.assignedByName}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={6}>
                                            <Field name="dateAssigned" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <div>
                                                        {/* tslint:disable-next-line: no-console */}
                                                        {/* {console.log({...input})} */}
                                                        <KeyboardDateTimePicker
                                                            {...input}
                                                            className={classes.textField + ' active'}
                                                            id="dateAssigned"
                                                            name="dateAssigned"
                                                            label="Date Assigned"
                                                            format="DD/MM/YYYY hh:mm a"
                                                            placeholder="dd/mm/yyyy hh:mm a"
                                                            required={true}
                                                            animateYearScrolling={true}
                                                            // tslint:disable-next-line: max-line-length
                                                            autoOk={true}
                                                            allowKeyboardControl={false}
                                                            variant={'inline'}
                                                            error={meta.error && meta.touched}
                                                            helperText={meta.error && meta.touched ? 'Date Assigned is required' : ''}
                                                            inputProps={{ autocomplete: 'off' }}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>
                                {/* {printJson(values)} */}
                            </form>
                        )}
                    />
                )}
            </Dialog>
        </>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: AssignLawyer) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }
